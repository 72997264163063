
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default defineComponent({
  name: "Config",
  setup() {
    const data = ref({
      id: null,
      phone: '',
      email: '',
      path_banner: "",
      path_banner2: "",
      path_banner3: "",
    });
    const loading = ref<boolean>(true);
    const idConfig = ref<number>(1);
    let image = ref<string>("");
    const files = ref<any>([]);
    const inputFile = ref();
    const fileload = ref(false);
    const isEdit = ref<boolean>(false);

    const formRef = ref<null | HTMLFormElement>(null);
    const rules = ref({
      email: [
        {
          required: true,
          message: "Escribe el email",
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          message: "Escribe teléfono",
          trigger: "blur",
        },
      ],
    });

    const getData = () => {
      loading.value = true;
      ApiService.get(`api/config-site/${idConfig.value}`)
        .then((result) => {
          loading.value = false;
          if (result.data.success) {
            data.value.email = result.data.data[0].email;
            const formatPhone = result.data.data[0].phone.replace(/\W/gi, '').replace(/(.{2})/g, '$1-');
            data.value.phone =  formatPhone.substring(0, formatPhone.length - 1)
            data.value.path_banner = result.data.data[0].path_banner;
            data.value.path_banner2 = result.data.data[0].path_banner2;
            data.value.path_banner3 = result.data.data[0].path_banner3;
          }
        })
        .catch(({ response }) => {
          loading.value = false;
          console.log(response);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          const dataConfig = JSON.parse(JSON.stringify(data.value));
          console.log("🚀 ~ file: ConfigView.vue ~ line 381 ~ formRef.value.validate ~ dataConfig", dataConfig)
          dataConfig.phone = dataConfig.phone.replace(/-/g,"");
          if (!dataConfig.id) {
            ApiService.put(`api/config-site/${idConfig.value}`, dataConfig)
              .then(({ data }) => {
                if (data.success) {
                  getData();
                  Swal.fire({
                    text: "¡Configuración guardada con éxito!",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  }).then(() => {
                    loading.value = false;
                  });
                }
              })
              .catch(({ response }) => {
                if (response !== undefined) {
                  let errors = "";
                  for (let key in response.data.errors) {
                    let error = response.data.errors[key];
                    for (let keyError in error) {
                      let errorMsg = error[keyError];
                      errors += errorMsg + "</br>";
                    }
                  }
                  loading.value = false;
                  Swal.fire({
                    title: "¡Error" + "!<br /><br />" + errors,
                    icon: "warning",
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  }).then((errors) => {
                    return false;
                  });
                }
              });
          }
        } else {
          return false;
        }
      });
    };

    const checkData = (evt) => {
        evt.preventDefault();
      };

      const addImage = () => {
        inputFile.value.click();
        
      };

      const deleteImage = async (index) => {
        inputFile.value = "";
        fileload.value = false;
        // formData.value.image = "";
      };


    const saveImage = (img) => {
      if (image.value) {
        loading.value = true;
        const formData = new FormData();
        formData.append(img, image.value);
        formData.append("id", idConfig.value.toString());
        ApiService.post(`api/config-site-banner`, formData)
          .then(({ data }) => {
            if (data.success) {
              getData();
              Swal.fire({
                text: "Banner guardado con éxito!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                loading.value = false;
              });
            }
          })
          .catch(({ response }) => {
            if (response !== undefined) {
              let errors = "";
              for (let key in response.data.errors) {
                let error = response.data.errors[key];
                for (let keyError in error) {
                  let errorMsg = error[keyError];
                  errors += errorMsg + "</br>";
                }
              }
              loading.value = false;
              getData();
              Swal.fire({
                title: "¡Error!</br>" + errors,
                icon: "warning",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
              }).then((errors) => {
                return false;
              });
            }
          });
      } else {
        return false;
      }
    };

    const changeInputFile = async (event, img) => {
      console.log(event, img);
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        image.value = file;
        data.value.path_banner = URL.createObjectURL(file);
        saveImage(img);
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Config", []);
      getData();
    });

    return {
      data,
      loading,
      idConfig,
      rules,
      formRef,
      changeInputFile,
      submit,
      files,
      inputFile,
      checkData,
      addImage,
      deleteImage,
      isEdit,
      fileload,

    };
  },
  methods: {
    cleanData() {
      this.data = {
        id: null,
        phone: '',
        email: '',
        path_banner: "",
        path_banner2: "",
        path_banner3: "",
      };
    },
  },
});
